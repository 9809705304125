import { create } from "zustand";

interface AppState {
  language: string
  setLanguage: (language: string) => void;
}

const useAppStore = create<AppState>((set) => ({
  language: 'es-ES',
  setLanguage: async function (language: string) {
    set({language});
  },
}));

export default useAppStore;
