
const supabaseClient = createClient(
  "https://pnlaffyibikmavpzbusj.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBubGFmZnlpYmlrbWF2cHpidXNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTgzMDEzNTAsImV4cCI6MjAxMzg3NzM1MH0.0C5APEYIyYtqjMwmt5fSb6FRAN5f1Jm1-54Kg0bqrgM",
  {}
);


import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import { SessionContextProvider, useUser } from "@supabase/auth-helpers-react";
import { createClient } from "@supabase/supabase-js";
import { LoginPage } from "./pages/Login";
import { Decks } from "./pages/Decks";
import { DeckView } from "./pages/DeckView";
import { DeckAdd } from "./pages/DeckAdd";
import { CreateCards } from "./pages/CreateCards";
import Study from "./pages/study/Study";
import {
  NavigationMenu,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "./components/ui/navigation-menu";
import { PlayIcon } from "@radix-ui/react-icons";
import { useEffect } from "react";
import useAppStore from "./store/store";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "./components/ui/select";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    Component: Layout,
    action: async () => {
      console.log("root action");
    },
    children: [
      {
        path: "decks",
        Component: Decks,
      },
      {
        path: "decks/:deckId",
        loader: async ({ params }) => {
          return (
            await supabaseClient
              .from("decks")
              .select("*")
              .eq("id", params.deckId)
              .single()
          ).data;
        },
        Component: DeckView,
      },
      {
        path: "decks/:deckId/add",
        // action: loginAction,
        //        loader: loginLoader,
        loader: async ({ params }) => {
          // return fetch(`/api/teams/${params.teamId}`);
          return (
            await supabaseClient
              .from("decks")
              .select("*")
              .eq("id", params.deckId)
              .single()
          ).data;
        },
        Component: DeckAdd,
      },
      {
        path: "create",
        Component: CreateCards,
      },
      {
        path: "create/many",
        Component: () => <CreateCards></CreateCards>,
      },
    ],
  },
  {
    path: "/study",
    Component: Study,
  },
  {
    path: "/logout",
    async action() {
      await supabaseClient.auth.signOut();
      return redirect("/");
    },
  },
  {
    path: "login",
    // action: loginAction,
    //        loader: loginLoader,
    Component: LoginPage,
  },
]);

export default function App() {
  return (
    <SessionContextProvider supabaseClient={supabaseClient}>
      <RouterProvider
        router={router}
        fallbackElement={<p>Initial Load...</p>}
      />
    </SessionContextProvider>
  );
}

export function Layout() {
  const user = useUser();
  const store = useAppStore();

  useEffect(()=> {
    store.setLanguage('es-ES');
  },[user]);

  return (
    <div className="flex flex-row min-h-screen bg-gray-100 text-gray-800">
      <main className="main flex flex-col flex-grow">
        <header className="header bg-white shadow-md py-2 px-4 z-10 border-b border-blue-600">
          <div className="header-content flex flex-row items-stretch">
            <div>
              <img src="logo.svg" height={"10px"}></img>
            </div>
            <NavigationMenu className="self-end">
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuLink to="/">Home</NavigationMenuLink>
                </NavigationMenuItem>
                <NavigationMenuItem>
                  <NavigationMenuLink to="/decks">Decks</NavigationMenuLink>
                </NavigationMenuItem>
                <NavigationMenuItem>
                  <NavigationMenuLink to="/create">Create</NavigationMenuLink>
                </NavigationMenuItem>
                <NavigationMenuItem>
                  <NavigationMenuLink to="/study">
                    Study
                    <PlayIcon></PlayIcon>
                  </NavigationMenuLink>
                </NavigationMenuItem>
              </NavigationMenuList>
              <NavigationMenuIndicator>asd</NavigationMenuIndicator>
            </NavigationMenu>

            <NavigationMenu className="ml-auto flex items-center space-x-4">
            <Select onValueChange={(language)=>{
              store.setLanguage(language);
            }} defaultValue={store.language}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select language" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Languages</SelectLabel>
                
                <SelectItem value="es-ES">Spanish</SelectItem>
                <SelectItem value="fr">French</SelectItem>

              </SelectGroup>
            </SelectContent>
          </Select>
              <NavigationMenuList>
                <NavigationMenuItem className="border-l">
                  <NavigationMenuLink
                    to="/user"
                    className={navigationMenuTriggerStyle()}
                  >
                    {user?.email}
                  </NavigationMenuLink>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
          </div>
        </header>
        <div className="main-content flex flex-col flex-grow bg-white">
          <Outlet />
        </div>
        {/* <footer className="footer px-4 py-6">
          <div className="footer-content">
            <p className="text-sm text-gray-600 text-center">
              © SlickFlash 2023. All rights reserved.{" "}
            </p>
          </div>
        </footer> */}
      </main>
      {/* <SiteFooter className="border-t" /> */}
    </div>
  );
}

// function AuthStatus() {
//   // Get our logged in user, if they exist, from the root route loader data
//   //  let { user } = useRouteLoaderData("root") as { user: string | null };
//   const fetcher = useFetcher();
//   const user = useUser();

//   if (!user) {
//     return <p>You are not logged in.</p>;
//   }

//   const isLoggingOut = fetcher.formData != null;

//   return (
//     <div>
//       <p>Welcome {user.email}!</p>
//       <fetcher.Form method="post" action="/logout">
//         <button type="submit" disabled={isLoggingOut}>
//           {isLoggingOut ? "Signing out..." : "Sign out"}
//         </button>
//       </fetcher.Form>
//     </div>
//   );
// }

// function protectedLoader({ request }: LoaderFunctionArgs) {
//   // If the user is not logged in and tries to access `/protected`, we redirect
//   // them to `/login` with a `from` parameter that allows login to redirect back
//   // to this page upon successful authentication
//   if (!fakeAuthProvider.isAuthenticated) {
//     const params = new URLSearchParams();
//     params.set("from", new URL(request.url).pathname);
//     return redirect("/login?" + params.toString());
//   }
//   return null;
// }
