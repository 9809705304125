type BatteryProps = {
  percentage: number;
  className?: string;
};

export const Battery: React.FC<BatteryProps> = ({ percentage, className }) => {
  const [min, max] = [4.5, 18];
  const range = max - min;

  const value = min + range * percentage;

  const hue = Math.floor(120 * percentage); // Hue for green is 120, red is 0

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      //width="20px"
      height="20px"
      className={`${className}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d={`M21 10.5h.375c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125H21zM3.75 18h15A2.25 2.25 0 0021 15.75v-6a2.25 2.25 0 00-2.25-2.25h-15A2.25 2.25 0 001.5 9.75v6A2.25 2.25 0 003.75 18z`}
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={`hsl(${hue}, 80%, 50%)`}
        d={`M4.5 10.5H${value.toFixed(2)}V15H4.5v-4.5z`}
      />
    </svg>
  );
};
