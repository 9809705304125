import { CreateCardsFromWords } from "./CreateCardFromWords";

export const CreateCards: React.FC = () => {
  // const supabase = useSupabaseClient();
  // const [decks, setDecks] = useState<Deck[]>([]);
  // const navigate = useNavigate();

  return (
    <div className="">
      <div className="flex items-center justify-between space-y-2 p-4 shadow-sm">
        <div className="flex">
          <h1 className="text-3xl font-bold tracking-tight">Create</h1>
        </div>
      </div>
      <div className="p-4">
        <CreateCardsFromWords></CreateCardsFromWords>
      </div>
    </div>
  );
};
