import { Battery } from "@/components/Battery";
import PlayAudio from "@/components/PlayAudio";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Table,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card, Deck } from "@/lib/database.types";

import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useEffect, useRef, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";

const EditableBack: React.FC<{
  card: Card;
  onUpdateCard: (card: Card) => void;
}> = ({ card, onUpdateCard }) => {
  const [isEditing, setEditing] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    console.log("input ref:", inputRef.current);
    if (inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  function save() {
    console.log('saving');
    setEditing(false);
    onUpdateCard(card);
  }

  //return ;
  return !isEditing ? (
    <div
      className="hover:bg-gray-200 box-content	"
      onDoubleClick={() => {
        setEditing(true);
      }}
    >
      {card.back}
      {card.back_audio ? (
        <PlayAudio bucket={card.back_audio} play={false} />
      ) : null}
    </div>
  ) : (
    <div className="flex flex-row">
      <Input
        ref={inputRef}
        className="flex-grow"
    //    onBlur={() => setEditing(false)}
    //    onAbort={() => setEditing(false)}
        defaultValue={card.back ?? ""}
      ></Input>
      <Button size={"sm"} onClick={save}>
        save
      </Button>
      <Label htmlFor="translate">Translate</Label>
      <Checkbox id="translate" />
    </div>
  );
};

export const DeckView: React.FC = () => {
  const deck = useLoaderData() as Deck;
  const supabase = useSupabaseClient();
  const [cards, setCards] = useState<Card[]>([]);

  useEffect(() => {
    async function loadData() {
      const result = await supabase
        .from("cards")
        .select("*, decks!inner(name)")
        .in("decks.id", [deck.id]);

      if (result.data) {
        setCards(result.data);
      }
    }

    loadData();
  }, [setCards, supabase, deck.id]);


  function updateCard(card: Card){
    console.log('saving card', card)
  }

  return (
    <div className="">
      <div className="flex items-center justify-between space-y-2 p-4 shadow-sm">
        <div className="flex">
          <h1 className="text-lg font-bold tracking-tight">
            <Link
              to="/decks"
              className="underline decoration-2 decoration-dashed hover:decoration-black decoration-gray-400"
            >
              Decks
            </Link>
            {" > "}
            {deck.name}
          </h1>
        </div>
        {/* <div className="flex items-center space-x-2">
          <Button>
            <Link to="add">Add</Link>
          </Button>
          <Button>Learn</Button>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button>...</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Profile</DropdownMenuItem>
              <DropdownMenuItem>Billing</DropdownMenuItem>
              <DropdownMenuItem>Team</DropdownMenuItem>
              <DropdownMenuItem>Subscription</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div> */}
      </div>
      <div className="p-4">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Front</TableHead>
              <TableHead>Back</TableHead>
              <TableHead>Strength</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          {cards.map((card) => (
            <TableRow>
              <TableCell>
                <div className="flex flex-row items-center  gap-1">
                  {card.front}

                  {card.front_audio ? (
                    <PlayAudio bucket={card.front_audio} play={false} />
                  ) : null}
                </div>
              </TableCell>
              <TableCell>
                <EditableBack card={card} onUpdateCard={updateCard}></EditableBack>
              </TableCell>
              <TableCell>
                <Battery percentage={card.average}></Battery>
              </TableCell>
              <TableCell>
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <Button variant="ghost" size={"sm"}>
                      ...
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuLabel>Card</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem>Delete</DropdownMenuItem>
                    <DropdownMenuItem>Forget</DropdownMenuItem>
                    {/* <DropdownMenuItem>Team</DropdownMenuItem>
                    <DropdownMenuItem>Subscription</DropdownMenuItem> */}
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
          <TableCaption>{cards.length} cards</TableCaption>
        </Table>
      </div>
    </div>
  );
};
