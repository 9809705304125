import { useSupabaseClient } from "@supabase/auth-helpers-react";
import React, { useEffect, useRef, useState } from "react";
import { FiPlayCircle, FiPauseCircle } from "react-icons/fi";

interface AudioPlayerProps {
  bucket: string;
  play: boolean;
}

const PlayAudio: React.FC<AudioPlayerProps> = ({ bucket, play }) => {
  const supabase = useSupabaseClient();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  useEffect(() => {
    async function getPublicUrl() {
      const publicUrl = (
        await supabase.storage.from("audio").getPublicUrl(bucket)
      ).data?.publicUrl;

      setAudioUrl(publicUrl);
    }

    getPublicUrl();
  }, [bucket, supabase]);

  useEffect(() => {
    console.log("ref updated");
    if (!audioRef.current) {
      return;
    }
    const audioElem = audioRef.current;

    if (play) {
      audioElem.play();
      setIsPlaying(true);
    } else {
      audioElem.pause();
      setIsPlaying(false);
    }
  }, [play, audioRef, audioUrl]);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    const audioElem = audioRef.current;

    const handleAudioEnd = () => {
      setIsPlaying(false);
    };
    audioElem.addEventListener("ended", handleAudioEnd);
    return () => {
      audioElem.removeEventListener("ended", handleAudioEnd);
    };
  }, [audioRef, isPlaying]);

  const handlePlayPause = () => {
    if (!audioRef.current) {
      return;
    }
    const audioElem = audioRef.current;

    if (isPlaying) {
      audioElem.pause();
      audioElem.fastSeek(0);
      setIsPlaying(false);
    } else {
      audioElem.play();
      setIsPlaying(true);
      console.log(`playing ${audioUrl}`);
    }
  };

  return audioUrl ? (
    <div>
      <audio ref={audioRef} src={audioUrl} />
      <div onClick={handlePlayPause}>
        {isPlaying ? (
          <FiPauseCircle size="12" color="gray" />
        ) : (
          <FiPlayCircle size="12" color="gray" />
        )}
      </div>
    </div>
  ) : null;
};

export default PlayAudio;
