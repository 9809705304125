import React, { useState, useEffect } from "react";
import { Card } from "../../lib/database.types";
import { SupabaseClient } from "@supabase/supabase-js";
import PlayAudio from "@/components/PlayAudio";

import { Battery } from "@/components/Battery";

interface CardProps {
  supabase: SupabaseClient;
  card: Card | null;
  curr: number | null;
  total: number | null;
  onCardDone: () => void;
}

const CardView: React.FC<CardProps> = ({
  onCardDone,
  card,
  supabase,
  curr,
  total,
}) => {
  const [revealed, setRevealed] = useState<boolean>(false);

  const reveal = () => {
    if (!revealed) {
      setRevealed(true);
    }
  };

  async function finished(correct: boolean) {
    if (!revealed) {
      return;
    }

    if (!card) {
      return;
    }


    // TODO: do it serverside
    const newAverage =
      ((correct ? 1 : 0) - card.average) * (2 / 4) + card.average;
    await supabase
      .from("cards")
      .update({ average: newAverage })
      .eq("id", card?.id);

    setRevealed(false);
    onCardDone();
  }

  useEffect(() => {
    const handleKeyboard = async (e: KeyboardEvent) => {
      if (e.key === " " || e.key === "Spacebar" || e.key === "ArrowRight") {
        reveal();
      }

      if (e.key === "ArrowUp") {
        await finished(true);
      }
      if (e.key === "ArrowDown") {
        finished(false);
      }
    };

    document.addEventListener("keydown", handleKeyboard);

    return () => {
      document.removeEventListener("keydown", handleKeyboard);
    };
  });

  // function getSubject(card: Card | null) {
  //   if (card === null || card?.metadata === null) {
  //     return "";
  //   }

  //   if (typeof card.metadata === "object" && !Array.isArray(card.metadata)) {
  //     return (card.metadata["subject"] as string) ?? "";
  //   }

  //   return "";
  // }

  return (
    <div className="relative sm:mx-auto sm:max-w-lg">
      <div
        className={`pb-2 flex justify-center text-xs transition ${
          !revealed ? "text-gray-400/0" : "text-gray-400/80"
        }`}
        onClick={() => {
          finished(true);
        }}
      >
        correct
      </div>
      <div className=" bg-white px-6 pb-4 pt-4 shadow-xl ring-1 ring-gray-900/5 sm:rounded-lg sm:px-4 min-w-[200px]">
        {curr ? (
          <div className="relative bg-blue text-xs text-gray-300 flex flex-row items-center justify-between">
            <div>
              {curr}/{total}
            </div>
            {card ? (
              <Battery percentage={card?.average} className=""></Battery>
            ) : null}
          </div>
        ) : null}
        <div className="divide-y divide-gray-300/50">
          <div className="py-2 text-xl text-gray-600 leading-10 flex flex-row items-center">
            {card?.front}
            {card?.front_audio ? (
              <PlayAudio bucket={card.front_audio} play={true}></PlayAudio>
            ) : null}
          </div>
          <div className="pt-3 text-base text-gray-600 leading-1">
            <div
              className={`pt-0 text-xs text-blue-300 leading-1 ${
                revealed ? "hidden" : ""
              }`}
            >
              {/*getSubject(card)*/}
            </div>
            {revealed ? (
              <div className="text-xl text-gray-600 leading-1 flex-row flex flex-row items-center">
                {card?.back}
                {card?.back_audio ? (
                  <PlayAudio
                    bucket={card.back_audio}
                    play={revealed}
                  ></PlayAudio>
                ) : null}
              </div>
            ) : (
              <div className="text-xl text-gray-300 leading-1" onClick={reveal}>
                touch to reveal
              </div>
            )}
          </div>
        </div>
        {/* <div
          className={`pt-3 flex flex-row-reverse text-xs text-gray-200 underline decoration-dotted ${
            !revealed ? "invisible" : ""
          }`}
        >
          touch card to see next
        </div> */}
      </div>
      <div
        className={`pt-2 flex justify-center text-xs transition ${
          !revealed ? "text-gray-400/0" : "text-gray-400/80"
        }`}
        onClick={() => {
          finished(false);
        }}
      >
        incorrect
      </div>
    </div>
  );
};

export default CardView;
