import { useEffect, useState } from "react";
import { Card, DbResult } from "../../lib/database.types";
import CardView from "./Card";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

function shuffleArray<T>(array: T[]): T[] {
  const newArray = array.slice(); // Create a copy of the original array

  for (let i = newArray.length - 1; i > 0; i--) {
    // Generate a random index between 0 and i (inclusive)
    const j = Math.floor(Math.random() * (i + 1));

    // Swap the elements at indices i and j
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }

  return newArray;
}

const Study: React.FC = () => {
  const supabase = useSupabaseClient();
  const [card, setCard] = useState<Card | null>(null);
  const [cards, setCards] = useState<Card[]>([]);
  const [currCard, setCurrCard] = useState<number>(0);

  useEffect(() => {
    loadCards();
  }, []);

  async function loadCards() {
    // , 'emotion','profession', 'location'

    console.log(await supabase.auth.getUser());

    const query = supabase
      .from("cards")
      //.select("*")
       .select("*, decks!inner(name)")
           .in("decks.name", ["Unassigned"])
      .order("average", { ascending: true })
      //.eq("id", 319)
      .limit(30);
    const data: DbResult<typeof query> = await query;

    if (data.data === null) {
      return;
    }

    const cards = shuffleArray(data.data);

    setCards(cards);
    setCurrCard(0);
    setCard(cards[0]);
  }

  async function updateCard() {
    if (currCard < cards.length - 1) {
      setCurrCard(currCard + 1);
      setCard(cards[currCard + 1]);
    }
  }

  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">
      <CardView
        card={card}
        onCardDone={updateCard}
        supabase={supabase}
        curr={currCard + 1}
        total={cards.length}
      ></CardView>
    </div>
  );
};

export default Study;
