import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";

import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useEffect, useRef, useState } from "react";

// interface Row {
//   isSelected: boolean;
//   data: CardInput;
// }

interface CardInput {
  front: string;
  back: string;
  translating: boolean;
  word?: string | undefined;
}

interface CreationInput {
  input: string;
  cards: CardInput[];
}

export const CreateCardsFromWords: React.FC = () => {
  const textRef = useRef<HTMLTextAreaElement | null>(null);
  const [creationInput, setCreationInput] = useState<CreationInput[]>([]);
  //  const [rows, setRows] = useState<Row[]>([]);

  const supabase = useSupabaseClient();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // function onSubmit(data: z.infer<typeof FormSchema>) {
  //   toast({
  //     title: "You submitted the following values:",
  //     description: (
  //       <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
  //         <code className="text-white">{JSON.stringify(data, null, 2)}</code>
  //       </pre>
  //     ),
  //   });
  // }

  useEffect(() => {
    async function translate(words: string[]) {
      if (words.length == 0) {
        return;
      }

      const result = await supabase.functions.invoke("translate-words", {
        body: { inputs: words },
      });

      console.log("translation result", result.data);
      const inputelem = creationInput.map((item) => {
        if (item.input in result.data) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const translation: any = result.data[item.input];
          const words = translation.spanish.split(" ").length;

          item.cards[0] = {
            front: translation.english,
            back: translation.spanish,
            translating: false,
            word:
              words == 1 ? translation.root || translation.spanish : undefined,
          };
        }

        return item;
      });
      console.log(inputelem);

      setCreationInput(inputelem);
    }

    const toTranslate = creationInput.flatMap((input) => {
      if (input.cards.some((card) => card.translating)) {
        return [input.input];
      }
      return [];
    });

    translate(toTranslate);
  }, [creationInput, supabase.functions]);

  // useEffect(() => {
  //   //    translate(toTranslate);
  //   const rows = creationInput.

  // }, [creationInput]);

  function addWords() {
    if (textRef.current == null) {
      return;
    }

    const text = textRef.current?.value;
    const toTranslate: string[] = [];

    const lines = text.split("\n");

    const newInputs = lines.flatMap((line) => {
      if (line.trim() === "") {
        return [];
      }

      const [back, front] = line.split(";");

      const cardInputs: CardInput[] = [];
      if (front && front.trim() !== "") {
        cardInputs.push({ front, back, translating: false, word: undefined });
      } else {
        cardInputs.push({
          front: "",
          back: "",
          translating: true,
          word: undefined,
        });
        toTranslate.push(back);
      }

      return [{ input: back, cards: cardInputs }];
    });

    const updatedCards = newInputs.filter((input) => {
      const oldCard = creationInput.find(
        (oldcard) => oldcard.input == input.input
      );

      return oldCard === undefined;
    });

    const newInput = creationInput.concat(updatedCards);
    setCreationInput(newInput);
  }

  async function createCards() {
    const cards = creationInput.flatMap((inputs) => inputs.cards);

    const result = await supabase.functions.invoke("create-cards", {
      body: cards,
    });

    toast({
      title: "Sucess",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          cards created
        </pre>
      ),
    });

    console.log(result);
  }

  // const deleteChild = (input: string, childIndex: number) => {
  //   const inputelem = creationInput.map((item) => {
  //     if (item.input === input) {
  //       item.cards = item.cards.filter((_, i) => i !== childIndex);
  //     }
  //     return item;
  //   });

  //   setCreationInput(inputelem);
  // };

  const createExamplesForWord = async (input: string) => {
    const result = await supabase.functions.invoke("create-example-sentences", {
      body: { inputs: [input] },
    });

    const data = result.data;
    const sentences = data[input];
    //    const cards = sentences.

    console.log(data, sentences);
    const inputelem = creationInput.map((item) => {
      if (item.input === input) {
        item.cards.push(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...sentences.map((sentence: any) => ({
            front: sentence.english,
            back: sentence.example,
          }))
        );
      }
      return item;
    });

    setCreationInput(inputelem);

    //    console.log("examples result", result.data);
  };

  return (
    <div>
      <div className="border-b">
        <Textarea ref={textRef}></Textarea>
        <Button onClick={addWords}>down</Button>
      </div>
      <div className="pt-8">
        <div className="flex flex-row-reverse">
          <Button onClick={createCards}>create cards</Button>

          <Select>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select a deck" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Decks</SelectLabel>
                decks
                {/* <SelectItem value="apple">Apple</SelectItem>
                <SelectItem value="banana">Banana</SelectItem>
                <SelectItem value="blueberry">Blueberry</SelectItem>
                <SelectItem value="grapes">Grapes</SelectItem>
                <SelectItem value="pineapple">Pineapple</SelectItem> */}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[20px]" rowSpan={2}>
                <Checkbox
                  // checked={table.getIsAllPageRowsSelected()}
                  // onCheckedChange={(value) =>
                  //   table.toggleAllPageRowsSelected(!!value)
                  // }
                  aria-label="Select all"
                />
              </TableHead>
              {/* <TableHead className="w-[150px]" rowSpan={2}>
                Input
              </TableHead> */}
              <TableHead colSpan={2}>Cards</TableHead>
              {/* <TableHead className="w-[100px]" rowSpan={2}>
                Deck
              </TableHead> */}
              {
                <TableHead className="w-[150px]" rowSpan={2}>
                  Input
                </TableHead>
              }
              <TableHead className="w-[100px] text-center" rowSpan={2}>
                Actions
              </TableHead>
            </TableRow>
            <TableRow className="border-b-4 border-solid border-yellow-500">
              <TableHead>Front</TableHead>
              <TableHead>Back</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {creationInput.map((input) => {
              const firstCard = input.cards[0];
              const otherCards = input.cards.slice(1);

              const otherRows = otherCards.map((card) => (
                <TableRow className="border-t border-dashed ">
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                  <TableCell>{card.front}</TableCell>
                  <TableCell>{card.back}</TableCell>
                </TableRow>
              ));

              return (
                <>
                  <TableRow className="border-t border-solid border-gray-300  border-b-0">
                    <TableCell>
                      <Checkbox />
                    </TableCell>
                    {/* <TableCell rowSpan={otherCards.length + 1}>
                      {input.input}
                    </TableCell> */}
                    <TableCell>{firstCard.front}</TableCell>
                    <TableCell>{firstCard.back}</TableCell>

                    {/* <TableCell rowSpan={otherCards.length + 1}>
                      <Select>
                        <SelectTrigger className="w-[180px]">
                          <SelectValue placeholder="Select a fruit" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Decks</SelectLabel>
                            <SelectItem value="apple">Apple</SelectItem>
                            <SelectItem value="banana">Banana</SelectItem>
                            <SelectItem value="blueberry">Blueberry</SelectItem>
                            <SelectItem value="grapes">Grapes</SelectItem>
                            <SelectItem value="pineapple">Pineapple</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </TableCell> */}
                    <TableCell
                      rowSpan={otherCards.length + 1}
                      className="border-l border-dashed"
                    >
                      {input.input}
                    </TableCell>
                    <TableCell
                      rowSpan={otherCards.length + 1}
                      className="border-l border-dashed"
                    >
                      <Button
                        size={"sm"}
                        variant={"link"}
                        onClick={() => {
                          createExamplesForWord(input.input);
                        }}
                      >
                        generate examples
                      </Button>
                    </TableCell>
                  </TableRow>
                  {otherRows}
                </>
              );
            })}

            {/* {creationInput.map((input) => (
              
              <>
                <TableRow className="group/row">
                  <TableCell>
                    <Checkbox
                      // checked={table.getIsAllPageRowsSelected()}
                      // onCheckedChange={(value) =>
                      //   table.toggleAllPageRowsSelected(!!value)
                      // }
                      aria-label="Select all"
                    />
                  </TableCell>
                  <TableCell>{input.input}</TableCell>
                  <TableCell>
                    <div className="flex flex-row">
                      <div className="flex-grow">
                        {input.cards.map((card, i) => (
                          <div className="grid group">
                            <div className="border rounded-md col-start-1 row-start-1 bg-white  ">
                              <div className="border-b p-1 border-gray-100 border-dashed">
                                {card.front}
                              </div>
                              <div className="p-1">
                                {card.back}
                                {card.word ? `(${card.word})` : null}
                              </div>
                            </div>
                            <div className="col-start-1 row-start-1 justify-self-end opacity-0 group-hover:visible group-hover:opacity-100 invisible transition duration-300	">
                              <Button
                                size={"sm"}
                                onClick={() => deleteChild(input.input, i)}
                              >
                                del
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Select>
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Select a fruit" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>Decks</SelectLabel>
                          <SelectItem value="apple">Apple</SelectItem>
                          <SelectItem value="banana">Banana</SelectItem>
                          <SelectItem value="blueberry">Blueberry</SelectItem>
                          <SelectItem value="grapes">Grapes</SelectItem>
                          <SelectItem value="pineapple">Pineapple</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </TableCell>
                  <TableCell className="text-left opacity-10 group-hover/row:opacity-100">
                    <Button size={"sm"} variant={"link"}>
                      translate
                    </Button>
                    <Button size={"sm"} variant={"link"}>
                      add examples
                    </Button>
                  </TableCell>
                </TableRow>
              </>
            ))} */}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
