import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import { Card, Deck } from "../lib/database.types";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableCell,
  TableFooter,
} from "@/components/ui/table";
import useAppStore from "@/store/store";

export const Decks: React.FC = () => {
  const supabase = useSupabaseClient();
  const [decks, setDecks] = useState<(Deck & { cards: Card[] })[]>([]);
  const navigate = useNavigate();
  const language = useAppStore((state) => state.language)


  const editDeck = (deck: Deck) => {
    navigate(`${deck.id}`, {});
  };

  useEffect(() => {
    async function loadDecks() {
      const result = await supabase.from("decks").select("*, cards(id)").eq('language_code', language);

      if (result.error == null) {
        setDecks(result.data);
      }
    }

    loadDecks();
  }, [supabase, language]);

  return (
    <>
      <div className="flex items-center justify-between space-y-2 p-2 shadow-sm">
        <h1 className="text-lg font-bold tracking-tight">Decks</h1>
        <div className="flex items-center space-x-2">
          <Button variant={"outline"} size={"sm"}>
            add deck
          </Button>
        </div>
      </div>
      <div className="p-4">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Number of cards</TableHead>
            </TableRow>
          </TableHeader>
          {decks.map((deck) => (
            <TableRow
              className="hover:bg-gray-100"
              onClick={() => {
                editDeck(deck);
              }}
            >
              <TableCell>{deck.name}</TableCell>
              <TableCell>{deck.cards.length}</TableCell>
            </TableRow>
          ))}
          <TableFooter>
            {/* <TableCell colSpan={3}>Total</TableCell>
            <TableCell className="text-right">$2,500.00</TableCell> */}

            <TableCell colSpan={1}> Total</TableCell>
            <TableCell colSpan={1}> Total</TableCell>
          </TableFooter>
        </Table>
      </div>
    </>
  );
};
