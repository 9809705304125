import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "../app/globals.css";
import { Toaster } from "./components/ui/toaster.tsx";
//import { LoginPage } from "./pages/Login.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App></App>
    <Toaster />
  </React.StrictMode>
);
